import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Logout from './routes/Auth/Logout';
import PrivateRoute from './components/common/PrivateRoute';
import ErrorBoundary from './components/common/ErrorBoundry';
import ScrollToTop from './components/common/ScrollToTop';
import PageLoading from './components/common/PageLoading';
import lazyRetry from './components/common/lazyRetry';
import LanguageProvider from './LanguageProvider';
import VerifyAccount from './routes/Auth/VerifyAccount';

import './index.css';
import './App.css';
import VerifyPayment from './routes/Auth/VerifyPayment';
import AccountFailure from './routes/Auth/AccountFailure';
import VersionControl from './VersionControl';

const App = lazyRetry(() => import('./App'));
const Login = lazyRetry(() => import('./routes/Auth/Login'));
const Register = lazyRetry(() => import('./routes/Auth/Register'));
const ForgotPassword = lazyRetry(() => import('./routes/Auth/ForgotPassword'));
const ResetPassword = lazyRetry(() => import('./routes/Auth/ResetPassword'));
const LevelUpPassword = lazyRetry(() => import('./routes/Auth/LevelUpPassword'));
const PasswordlessLogin = lazyRetry(() => import('./routes/Auth/PasswordlessLogin'));

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <VersionControl>
            <I18nextProvider i18n={i18n}>
              <ThemeSwitcherProvider themeMap={themes} defaultTheme={store.getState().ui.theme}>
                <LanguageProvider>
                  <BrowserRouter>
                    {navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1 ||
                    navigator.userAgent.toUpperCase().indexOf('MSIE') !== -1 ? (
                      <h4>Browser is not supported. Please use latest version of chrome,firefox,opera,... browsers</h4>
                    ) : (
                      <ScrollToTop>
                        <Routes>
                          <Route
                            path="/*"
                            element={
                              <PrivateRoute>
                                <React.Suspense fallback={<PageLoading />}>
                                  <App />
                                </React.Suspense>
                              </PrivateRoute>
                            }
                          />
                          <Route
                            path="login"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <Login />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="register"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <Register />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="reset-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <ResetPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="level-up-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <LevelUpPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="forgot-password"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <ForgotPassword />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="passwordless-login"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <PasswordlessLogin />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="verify-account"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <VerifyAccount />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="account-subscription"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <VerifyPayment />
                              </React.Suspense>
                            }
                          />
                          <Route
                            path="account-failure"
                            element={
                              <React.Suspense fallback={<PageLoading />}>
                                <AccountFailure />
                              </React.Suspense>
                            }
                          />
                          <Route path="logout" element={<Logout />} />
                          <Route
                            path="*"
                            element={
                              <main style={{ padding: '1rem' }}>
                                <p>There's nothing here!</p>
                              </main>
                            }
                          />
                        </Routes>
                      </ScrollToTop>
                    )}
                  </BrowserRouter>
                </LanguageProvider>
              </ThemeSwitcherProvider>
            </I18nextProvider>
          </VersionControl>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
