import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './apiSlice';
// import jwtDecoder from 'jwt-decode';
// import { userLogin } from '../auth/authSlice';
// import { extendedAuthApiSlice } from '../auth/authService';

export interface PasswordLessLoginType {
  user_email: string;
  secret_word: string;
  captcha_token?: string;
}
export interface PasswordLessStatusType {
  userIdentification: string;
}

export const sumConnectorService = createApi({
  reducerPath: 'sumC',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_SUM_CONNECTOR_URL as string}/`,
  }),
  tagTypes: [],
  endpoints(builder) {
    return {
      passwordLessLogin: builder.mutation<any, PasswordLessLoginType>({
        query: (loginData) => ({
          url: `api/auth/${process.env.REACT_APP_LUMBERJACK_IDENTIFICATION}/login/passless`,
          method: 'POST',
          data: loginData,
        }),
      }),

      passwordLessAccess: builder.mutation<any, PasswordLessLoginType>({
        query: (loginData) => ({
          url: `api/auth/${process.env.REACT_APP_LUMBERJACK_IDENTIFICATION}/login/passless/access`,
          method: 'POST',
          data: loginData,
        }),
      }),
    };
  },
});

export const { usePasswordLessLoginMutation, usePasswordLessAccessMutation } = sumConnectorService;
