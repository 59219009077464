import axios from 'axios';
import { store } from '../app/store';
import { toggleRenewPaymentRequiredStatus } from './auth/authSlice';
import { message } from 'antd';

const httpService = axios.create();

httpService.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 402 && error.response.data.code === 'RSPYKO001')
    store.dispatch(toggleRenewPaymentRequiredStatus(true));

  if (error.response && error.response.status === 401 && error.response.data.code === 'ISJWTO001') {
    message.error(error.response.data.message || 'Token invalid!');

    if (!error.response.request.responseURL.includes('passless/status')) {
      window.location.href = '/logout';
    }
  }

  return Promise.reject(error);
});

const service = {
  axios: httpService,
  get: httpService.get,
  post: httpService.post,
  put: httpService.put,
  patch: httpService.patch,
  delete: httpService.delete,
};

export default service;
