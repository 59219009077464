import httpService from '../httpService';
import { apiSlice, getToken } from '../api/apiSlice';
import { jwtDecode } from 'jwt-decode';
import { userLogin } from './authSlice';
export interface LoginType {
  user_email: string;
  user_password: string;
  remember_me: boolean;
}
export interface RegisterType {
  user_first_name?: string;
  user_last_name?: string;
  user_birth_date?: string;
  user_country_code?: string;
  user_user_name?: string;
  user_email: string;
  user_password: string;
  // user_confirm_password: string;
  user_agree_terms: boolean;
}

export interface UpdateProfileType {
  new_user_first_name: string;
  new_user_last_name: string;
  new_user_birth_date: string;
  new_user_country_code: string;
  new_user_user_name: string;
  new_user_email: string;
  user_password: string;
}

export interface UpdateProfileAvatarType {
  avatar: string;
  user_password: string;
}

export interface DeleteProfileAvatarType {
  user_password: string;
}

export interface ChangePasswordType {
  actual_password: string;
  new_password: string;
}

export interface ResetPasswordType {
  new_password: string;
  new_confirm_password: string;
}

export interface ChangeEmailType {
  user_email: string;
  new_user_email: string;
  new_user_email_confirmation: string;
}

export const extendedAuthApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginType>({
      query: (loginData) => ({
        url: `auth/login`,
        method: 'POST',
        data: loginData,
      }),
      transformResponse: (response) => response.element,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { token, ...rest } = data;

          const decodedPayload = jwtDecode<{ [key: string]: any }>(token);

          dispatch(extendedAuthApiSlice.endpoints.verifyPayment.initiate(decodedPayload.userIdentification));

          dispatch(
            userLogin({
              token,
              user_identification: decodedPayload.userIdentification,
              userEmail: args.user_email,
              ...rest,
            })
          );
        } catch (err: any) {
          if (err.error && err.error.status === 400 && err.error.data.code === 'RSUSKO014') {
            const { token, ...rest } = err.error.data.element;

            const decodedPayload = jwtDecode<{ [key: string]: any }>(token);

            dispatch(extendedAuthApiSlice.endpoints.verifyPayment.initiate(decodedPayload.userIdentification));

            dispatch(
              userLogin({
                token,
                user_identification: decodedPayload.userIdentification,
                userEmail: args.user_email,
                ...rest,
              })
            );
          }
        }
      },
    }),

    register: builder.mutation<any, RegisterType>({
      query: (registerData) => ({
        url: `auth/register`,
        method: 'POST',
        data: registerData,
      }),
      transformResponse: (response) => response.element,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { token, ...rest } = data;

          const decodedPayload = jwtDecode<{ [key: string]: any }>(token);

          dispatch(
            userLogin({
              token,
              user_identification: decodedPayload.userIdentification,
              userEmail: args.user_email,
              ...rest,
            })
          );
        } catch (error) {}
      },
    }),

    passwordLessStatus: builder.mutation<any, { customer_identification: string; secret_word: string }>({
      query: (data) => ({
        url: `auth/${data.customer_identification}/login/passless/status`,
        method: 'POST',
        data: {
          secret_word: data.secret_word,
        },
      }),
    }),

    passlessVerify: builder.mutation<any, { customer_identification: string; token: string }>({
      query: ({ customer_identification, token }) => ({
        url: `auth/login/passless/verify`,
        method: 'POST',
        data: {
          user_identification: customer_identification,
          token: token,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { token, ...rest } = data.element;

          const decodedPayload = jwtDecode<{ [key: string]: any }>(token);

          dispatch(
            userLogin({
              token,
              user_identification: decodedPayload.userIdentification,
              userIsActive: true,
              ...rest,
            })
          );

          // dispatch(extendedAuthApiSlice.endpoints.checkStatus.initiate(decodedPayload.userIdentification));

          // dispatch(extendedAuthApiSlice.endpoints.verifyPayment.initiate(decodedPayload.userIdentification));
        } catch (err: any) {}
      },
    }),

    profile: builder.query<any, string>({
      query: (customer_identification) => ({
        url: `${customer_identification}/profile`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      providesTags: ['Profile'],
    }),

    updateProfile: builder.mutation<any, { customer_identification: string; updateProfileData: UpdateProfileType }>({
      query: ({ customer_identification, updateProfileData }) => ({
        url: `${customer_identification}/profile`,
        method: 'PATCH',
        headers: {
          'x-access-token': getToken() as string,
        },
        data: updateProfileData,
      }),
      invalidatesTags: (result, error, arg) => (result ? ['Profile'] : []),
    }),

    updateProfileAvatar: builder.mutation<
      any,
      {
        customer_identification: string;
        updateProfilAvatarData: UpdateProfileAvatarType;
      }
    >({
      query: ({ customer_identification, updateProfilAvatarData }) => ({
        url: `${customer_identification}/profile/avatar`,
        method: 'POST',
        headers: {
          'x-access-token': getToken() as string,
        },
        data: updateProfilAvatarData,
      }),
      invalidatesTags: (result, error, arg) => (result ? ['Profile'] : []),
    }),

    deleteProfileAvatar: builder.mutation<
      any,
      {
        customer_identification: string;
      }
    >({
      query: ({ customer_identification }) => ({
        url: `${customer_identification}/profile/avatar`,
        method: 'DELETE',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
      invalidatesTags: (result, error, arg) => (result ? ['Profile'] : []),
    }),

    changePassword: builder.mutation<
      any,
      {
        customer_identification: string;
        changePasswordData: ChangePasswordType;
      }
    >({
      query: ({ customer_identification, changePasswordData }) => ({
        url: `auth/${customer_identification}/password`,
        method: 'PATCH',
        data: changePasswordData,
      }),
    }),

    forgotPassword: builder.mutation<any, string>({
      query: (user_email) => ({
        url: `auth/password?email_address=${user_email}`,
        method: 'GET',
      }),
    }),

    resetPassword: builder.mutation<
      any,
      {
        customer_identification: string;
        token: string;
        resetPasswordData: ResetPasswordType;
      }
    >({
      query: ({ customer_identification, token, resetPasswordData }) => ({
        url: `auth/${customer_identification}/${token}/password`,
        method: 'PATCH',
        data: resetPasswordData,
      }),
    }),

    verifyPayment: builder.query<any, string>({
      query: (customer_identification) => ({
        url: `auth/${customer_identification}/verify-payment`,
        method: 'GET',
      }),
      providesTags: ['VerifyPayment'],
    }),

    resendValidationLink: builder.mutation<any, string>({
      query: (customer_identification) => ({
        url: `auth/${customer_identification}/validation-link`,
        method: 'POST',
        data: null,
      }),
    }),

    changeEmail: builder.mutation<
      any,
      {
        customer_identification: string;
        changeEmailData: ChangeEmailType;
      }
    >({
      query: ({ customer_identification, changeEmailData }) => ({
        url: `auth/${customer_identification}/change-email`,
        method: 'PATCH',
        data: changeEmailData,
      }),
    }),

    checkPassword: builder.mutation<
      any,
      {
        password: string;
        level?: string | null | undefined;
      }
    >({
      query: ({ password, level }) => ({
        url: `auth/check-password`,
        method: 'POST',
        data: {
          password,
          level,
        },
      }),
    }),

    checkStatus: builder.mutation<any, string>({
      query: (customer_identification) => ({
        url: `${customer_identification}/profile/status`,
        method: 'GET',
        headers: {
          'x-access-token': getToken() as string,
        },
      }),
    }),

    socialAuth: builder.mutation<any, any>({
      query: (providerPayload) => ({
        url: `auth/social/login`,
        method: 'POST',
        data: providerPayload,
      }),
      transformResponse: (response) => response.element,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { token, ...rest } = data;

          const decodedPayload = jwtDecode<{ [key: string]: any }>(token);

          dispatch(
            userLogin({
              token,
              user_identification: decodedPayload.userIdentification,
              ...rest,
            })
          );
        } catch (error) {}
      },
    }),
  }),
});

const resendEmailVerification = async (customer_identification: string) => {
  const res = await httpService.post(
    `${process.env.REACT_APP_BASE_URL}/auth/${customer_identification}/validation-link`
  );

  return res;
};

const AuthService = {
  resendEmailVerification,
};

export type PermissionKeysType =
  | 'EMAIL_REPORT'
  | '404_TRACKER'
  | 'EXPERT_SUPPORT'
  | 'BRANDED_QR_CODE'
  | 'CUSTOM_LINK'
  | 'DEDICATED_EXPERT'
  | 'CUSTOM_DASHBOARD'
  | 'MULTIPLE_USERS'
  | 'ONE_CLICK_EXPORT'
  | 'SUPPORT_12'
  | 'LINK_LIMIT_100'
  | 'QR_CODE'
  | 'CUSTOM_BUTTON'
  | 'BALANCE_TARGETING'
  | 'OS_TARGETING'
  | 'BULK_INLINE'
  | 'BULK_CSV'
  | 'CALL_TO_ACTION'
  | 'COUNTRY_TARGETING'
  | 'OPEN_API'
  | 'SECURE_DESTINATION'
  | 'UPDATE_TARGET'
  | 'CUSTOM_QR_CODE'
  | 'LINK_TRACKER'
  | 'LINK_LIMIT_10000'
  | 'LINK_LIMIT_500'
  | 'EDIT_BILLING'
  | 'EDIT_PROFILE'
  | 'TIME_TARGETING'
  | 'CONTACT_US'
  | 'LINK_LIMIT_5'
  | 'PIL';

export const PermissionsList = {
  EMAIL_REPORT: 'EMAIL_REPORT',
  '404_TRACKER': '404_TRACKER',
  EXPERT_SUPPORT: 'EXPERT_SUPPORT',
  BRANDED_QR_CODE: 'BRANDED_QR_CODE',
  CUSTOM_LINK: 'CUSTOM_LINK',
  DEDICATED_EXPERT: 'DEDICATED_EXPERT',
  CUSTOM_DASHBOARD: 'CUSTOM_DASHBOARD',
  MULTIPLE_USERS: 'MULTIPLE_USERS',
  ONE_CLICK_EXPORT: 'ONE_CLICK_EXPORT',
  SUPPORT_12: 'SUPPORT_12',
  LINK_LIMIT_100: 'LINK_LIMIT_100',
  QR_CODE: 'QR_CODE',
  CUSTOM_BUTTON: 'CUSTOM_BUTTON',
  BALANCE_TARGETING: 'BALANCE_TARGETING',
  OS_TARGETING: 'OS_TARGETING',
  BULK_INLINE: 'BULK_INLINE',
  BULK_CSV: 'BULK_CSV',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  COUNTRY_TARGETING: 'COUNTRY_TARGETING',
  OPEN_API: 'OPEN_API',
  SECURE_DESTINATION: 'SECURE_DESTINATION',
  UPDATE_TARGET: 'UPDATE_TARGET',
  CUSTOM_QR_CODE: 'CUSTOM_QR_CODE',
  LINK_TRACKER: 'LINK_TRACKER',
  LINK_LIMIT_10000: 'LINK_LIMIT_10000',
  LINK_LIMIT_500: 'LINK_LIMIT_500',
  EDIT_BILLING: 'EDIT_BILLING',
  EDIT_PROFILE: 'EDIT_PROFILE',
  TIME_TARGETING: 'TIME_TARGETING',
  CONTACT_US: 'CONTACT_US',
  LINK_LIMIT_5: 'LINK_LIMIT_5',
  PIL: 'PIL',
};

export const {
  useLoginMutation,
  useProfileQuery,
  useLazyProfileQuery,
  useRegisterMutation,
  useUpdateProfileMutation,
  useUpdateProfileAvatarMutation,
  useDeleteProfileAvatarMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyPaymentQuery,
  useLazyVerifyPaymentQuery,
  useResendValidationLinkMutation,
  useChangeEmailMutation,
  useCheckPasswordMutation,
  useCheckStatusMutation,
  useSocialAuthMutation,
  usePasswordLessStatusMutation,
  usePasslessVerifyMutation,
} = extendedAuthApiSlice;

export default AuthService;
